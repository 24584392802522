import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import TileGrid from 'ol/tilegrid/TileGrid';
import {getWidth} from 'ol/extent';

import {get as getProjection} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';

import Collection from 'ol/Collection.js';
import Attribution from 'ol/control/Attribution';
import Zoom from 'ol/control/Zoom';
import ZoomSlider from 'ol/control/ZoomSlider';
import ScaleLine from 'ol/control/ScaleLine';
import LayerSwitcher from 'ol-layerswitcher';

proj4.defs("EPSG:27700","+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs");
register(proj4);

var map = new Map({
    target: 'map',
    layers: [
        new TileLayer({
            title: 'Zoomstack Outdoor',
            type: 'base',
            visible: true,
            opacity: 1,
            source: new TileWMS({
                attributions: 'OS Zoomstack',
                url: 'https://maps.dev-intrbiz.user.intrbiz.cloud/qgis?MAP=zoomstack_outdoor.qgz&',
                params: {'LAYERS': 'Zoomstack', 'TILED': "TRUE"},
                serverType: 'qgis',
                projection: "EPSG:27700",
                hidpi: true,
                gutter: 64
            })
        }),
        new TileLayer({
            title: 'Zoomstack Roads',
            type: 'base',
            visible: false,
            opacity: 1,
            source: new TileWMS({
                attributions: 'OS Zoomstack',
                url: 'https://maps.dev-intrbiz.user.intrbiz.cloud/qgis?MAP=zoomstack_roads.qgz&',
                params: {'LAYERS': 'Zoomstack', 'TILED': true},
                serverType: 'qgis',
                projection: "EPSG:27700",
                hidpi: true,
                gutter: 64
            })
        }),
        new TileLayer({
            title: 'Zoomstack Light',
            type: 'base',
            visible: false,
            opacity: 1,
            source: new TileWMS({
                attributions: 'OS Zoomstack',
                url: 'https://maps.dev-intrbiz.user.intrbiz.cloud/qgis?MAP=zoomstack_light.qgz&',
                params: {'LAYERS': 'Zoomstack', 'TILED': true},
                serverType: 'qgis',
                projection: "EPSG:27700",
                hidpi: true,
                gutter: 64
            })
        }),
        new TileLayer({
            title: 'Zoomstack Night',
            type: 'base',
            visible: false,
            opacity: 1,
            source: new TileWMS({
                attributions: 'OS Zoomstack',
                url: 'https://maps.dev-intrbiz.user.intrbiz.cloud/qgis?MAP=zoomstack_night.qgz&',
                params: {'LAYERS': 'Zoomstack', 'TILED': true},
                serverType: 'qgis',
                projection: "EPSG:27700",
                hidpi: true,
                gutter: 64
            })
        })
    ],
    controls: createControls(),
    view: new View({
        projection: 'EPSG:27700',
        center: [ 346696.2390884308843, 310683.9433921412565 ],
        zoom: 14,
        minZoom: 0,
        maxZoom: 22
    })
});


function createControls() {
    var controls = new Collection();
    controls.push(new Zoom());
    controls.push(new ZoomSlider());
    controls.push(new Attribution({ collapsible: false }));
    controls.push(new ScaleLine({ }));
    controls.push(new LayerSwitcher({ tipLabel: 'Layers' }));
    return controls;
}
